<template>
  <div class="divBox">
    <form-panel
      ref="formPanel"
      labelWidth="150px"
      v-bind="submitConfig"
      :form="params"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item label="报名人">
        <span>{{ form.applyName }}</span>
      </el-form-item>
      <el-form-item label="报名人房号">
        <span>{{ form.roomName }}</span>
      </el-form-item>
      <el-form-item label="报名状态">
        <span>{{ applyStatusMap[form.applyStatus] }}</span>
      </el-form-item>
      <el-form-item label="参与人">
        <span>{{ form.memberName }}</span>
      </el-form-item>
      <!-- <el-form-item label="参与人手机号">
        <span>{{}}</span>
      </el-form-item> -->
      <el-form-item label="参与人身份证号码">
        <span>{{ form.memberCode }}</span>
      </el-form-item>
      <el-form-item label="性别">
        <span>{{ sexMap[form.memberSex] }}</span>
      </el-form-item>
      <el-form-item label="报名时间">
        <span>{{ form.applyTime }}</span>
      </el-form-item>
      <el-form-item label="报名备注">
        <span>{{ form.remark }}</span>
      </el-form-item>
      <el-form-item label="场次开始时间">
        <span>{{ form.startDate }}</span>
      </el-form-item>
      <el-form-item label="场次结束时间">
        <span>{{ form.endDate }}</span>
      </el-form-item>
      <el-form-item label="签到时间">
        <span>{{ form.signInTime }}</span>
      </el-form-item>
      <el-form-item label="签出时间">
        <span>{{ form.signOffTime }}</span>
      </el-form-item>

      <el-form-item
        label="获得积分数目"
        :rules="[
          {
            required: true,
            message: '请输入获得积分数目',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ]"
        prop="earnPoint"
      >
        <v-input
          placeholder="请输入获得积分数目"
          v-model.number="params.earnPoint"
          min="1"
          :width="width"
        ></v-input>
      </el-form-item>

      <el-form-item label="承诺书签署">
        <v-upload :limit="1" disabled :imgUrls.sync="form.signatureImage" />
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { integrateCompileUrl, volunteerSignInList } from "./api.js";

export default {
  name: "activityPointForm",
  data() {
    return {
      width: 220,
      submitConfig: {
        queryUrl: volunteerSignInList,
        submitUrl: integrateCompileUrl,
      },
      params: {
        // recordId: "", // 记录id
        applicantsId: "", // 参与人id
        earnPoint: undefined, // 更改积分数量
      },
      applyStatusMap: {
        0: "正常",
        1: "报名人取消",
        2: "发起人取消",
      },
      sexMap: {
        1: "男",
        2: "女",
      },
      form: {
        id: undefined,
        roomId: "", // 房号id
        recordId: "", // 记录id
        memberId: "", // 参与人id
        roomName: "", // 房号
        applyUserId: "", // 报名人id
        applyName: "", // 报名人姓名
        applyStatus: undefined, // 报名状态
        memberName: undefined, // 参与人姓名
        memberCode: undefined, // 参与人身份证号
        // memberPhone: 0, //参与人手机号
        memberSex: undefined, // 参与人性别
        applyTime: 0, //报名时间
        remark: 0, //报名备注信息
        startDate: "", // 场次开始时间
        endDate: "", // 场次结束时间
        signInTime: "", // 签到时间
        signOffTime: "", // 签出时间
        signInNum: "", // 签到次数
        signatureImage: "", // 承诺书
      },
    };
  },
  created() {
    const { recordId, activityId, applicantsId, scheduleId } =
      this.$route.query;
    // this.params.recordId = recordId;
    this.params.applicantsId = applicantsId;
    if (recordId !== undefined) {
      this.$nextTick(() => {
        this.$refs.formPanel.getData({
          recordId,
          activityId,
          scheduleId,
          applicantsId,
        });
      });
    }
    // this.submitConfig.submitUrl =
    //   id !== undefined ? updateTopicURL : addTopicURL;
    this.$setBreadList([{ title: "参与人列表" }, { title: "编辑积分" }]);
  },
  methods: {
    update(data) {
      this.params.earnPoint = data.earnPoint;
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
      });
    },
    submitBefore() {
      // return true;
      return {
        getPointNum: this.params.earnPoint,
        hourseId: this.form.hourseId,
        recordId: this.form.recordId,
        applyUserId: this.form.applyUserId,
        tenantId: this.$store.state.app.userInfo.tenantId, // 固定写死为“荷蜂社区”
      };
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.divBox {
  box-sizing: border-box;
  height: 100%;
}
</style>
