var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"divBox"},[_c('form-panel',_vm._b({ref:"formPanel",attrs:{"labelWidth":"150px","form":_vm.params,"submitBefore":_vm.submitBefore,"submitSuccess":_vm.submitSuccess},on:{"update":_vm.update}},'form-panel',_vm.submitConfig,false),[_c('el-form-item',{attrs:{"label":"报名人"}},[_c('span',[_vm._v(_vm._s(_vm.form.applyName))])]),_c('el-form-item',{attrs:{"label":"报名人房号"}},[_c('span',[_vm._v(_vm._s(_vm.form.roomName))])]),_c('el-form-item',{attrs:{"label":"报名状态"}},[_c('span',[_vm._v(_vm._s(_vm.applyStatusMap[_vm.form.applyStatus]))])]),_c('el-form-item',{attrs:{"label":"参与人"}},[_c('span',[_vm._v(_vm._s(_vm.form.memberName))])]),_c('el-form-item',{attrs:{"label":"参与人身份证号码"}},[_c('span',[_vm._v(_vm._s(_vm.form.memberCode))])]),_c('el-form-item',{attrs:{"label":"性别"}},[_c('span',[_vm._v(_vm._s(_vm.sexMap[_vm.form.memberSex]))])]),_c('el-form-item',{attrs:{"label":"报名时间"}},[_c('span',[_vm._v(_vm._s(_vm.form.applyTime))])]),_c('el-form-item',{attrs:{"label":"报名备注"}},[_c('span',[_vm._v(_vm._s(_vm.form.remark))])]),_c('el-form-item',{attrs:{"label":"场次开始时间"}},[_c('span',[_vm._v(_vm._s(_vm.form.startDate))])]),_c('el-form-item',{attrs:{"label":"场次结束时间"}},[_c('span',[_vm._v(_vm._s(_vm.form.endDate))])]),_c('el-form-item',{attrs:{"label":"签到时间"}},[_c('span',[_vm._v(_vm._s(_vm.form.signInTime))])]),_c('el-form-item',{attrs:{"label":"签出时间"}},[_c('span',[_vm._v(_vm._s(_vm.form.signOffTime))])]),_c('el-form-item',{attrs:{"label":"获得积分数目","rules":[
          {
            required: true,
            message: '请输入获得积分数目',
            trigger: ['blur', 'change'],
          },
          {
            message: '必须是数字',
            pattern: /^[0-9]+$/,
            trigger: 'blur',
          },
        ],"prop":"earnPoint"}},[_c('v-input',{attrs:{"placeholder":"请输入获得积分数目","min":"1","width":_vm.width},model:{value:(_vm.params.earnPoint),callback:function ($$v) {_vm.$set(_vm.params, "earnPoint", _vm._n($$v))},expression:"params.earnPoint"}})],1),_c('el-form-item',{attrs:{"label":"承诺书签署"}},[_c('v-upload',{attrs:{"limit":1,"disabled":"","imgUrls":_vm.form.signatureImage},on:{"update:imgUrls":function($event){return _vm.$set(_vm.form, "signatureImage", $event)},"update:img-urls":function($event){return _vm.$set(_vm.form, "signatureImage", $event)}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }